import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { MaterialReactTable } from "material-react-table";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import Copyright from "../internals/components/Copyright";

export default function YourApiGrid() {
  const [apiKeys, setApiKeys] = useState([]);
  const token = localStorage.getItem("idToken");
  const userEmail = localStorage.getItem("userEmail");

  useEffect(() => {
    const firestore = getFirestore();

    const fetchApiKeys = async () => {
      if (!userEmail) {
        console.error("User email is null or undefined.");
        return;
      }

      try {
        // Mengambil dokumen dari Firestore
        const apiKeyDoc = doc(firestore, "apiKey", userEmail);
        const apiKeyDocSnapshot = await getDoc(apiKeyDoc);

        if (apiKeyDocSnapshot.exists()) {
          const apiKeyData = apiKeyDocSnapshot.data();
          setApiKeys([{ id: userEmail, apiKey: apiKeyData.apiKey }]);
        } else {
          console.error("No API key found for this user.");
          setApiKeys([]);
        }
      } catch (error) {
        console.error("Error fetching API keys:", error);
      }
    };

    fetchApiKeys();
  }, [userEmail]);

  const handleCreateApiKey = async () => {
    if (!token) {
      Swal.fire("Error", "Token not found. Please log in again.", "error");
      return;
    }

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to create a new API Key? This will replace the existing one.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, create it!",
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(
          "https://api.adagempa.cloud/generate-api-key",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          const errorText = await response.text();
          console.error("Response Status:", response.status);
          console.error("Response Text:", errorText);
          throw new Error("Failed to create API key");
        }

        const data = await response.json();
        Swal.fire("Created!", `API Key created: ${data.apiKey}`, "success");

        // Perbarui API key yang ditampilkan
        setApiKeys([{ id: userEmail, apiKey: data.apiKey }]);
      } catch (error) {
        console.error("Error creating API key:", error);
        Swal.fire(
          "Error",
          "Error creating API key. Please try again.",
          "error"
        );
      }
    }
  };

  // Check if there are existing API keys
  const hasApiKey = apiKeys && apiKeys.length > 0;
  const buttonText = hasApiKey ? "Recreate API Key" : "Create API Key";

  // Column configuration for the table
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "User ID",
      },
      {
        accessorKey: "apiKey",
        header: "API Key",
      },
    ],
    []
  );

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      {/* Statistic Cards */}
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        YOUR API KEY
      </Typography>

      {/* Create/Recreate API Key Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleCreateApiKey}
        sx={{ mb: 2 }}
      >
        {buttonText}
      </Button>

      {/* API Keys Table */}
      <MaterialReactTable columns={columns} data={apiKeys} />

      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}
