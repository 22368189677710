import { onAuthStateChanged } from "firebase/auth"; 
import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "./AuthContext";
import SignIn from "./Authentication/SignIn/SignIn";
import SignUp from "./Authentication/SingUp/SignUp";
import Dashboard from "./Dashboard/Dashboard";
import Earthquake from "./Dashboard/Earthquake";
import YourApiKey from "./Dashboard/YourApiKey";
import { auth } from "./firebaseConfig";
import ProtectedRoute from "./ProtectedRoute";

function App() {
  const { user } = useAuth();

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Refresh the ID token
        user
          .getIdToken(true)
          .then((idToken) => {
            // Store the refreshed ID token in localStorage
            localStorage.setItem("idToken", idToken);
          })
          .catch((error) => {
            console.error("Failed to refresh ID token:", error);
          });
      }
    });

    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <Routes>
      {/* Default route redirects to Sign In */}
      <Route path="/" element={<Navigate to="/login" />} />

      {/* Jika pengguna sudah login, arahkan ke Dashboard */}
      <Route
        path="/login"
        element={user ? <Navigate to="/dashboard" /> : <SignIn />}
      />
      <Route
        path="/register"
        element={user ? <Navigate to="/dashboard" /> : <SignUp />}
      />

      {/* Protected route untuk Dashboard */}
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />

      {/* Protected route untuk Your API Key */}
      <Route
        path="/dashboard/your-api-key"
        element={
          <ProtectedRoute>
            <YourApiKey />
          </ProtectedRoute>
        }
      />

      {/* Protected route untuk User Client */}
      {/* <Route
        path="/dashboard/clients"
        element={
          <ProtectedRoute>
            <UserClient />
          </ProtectedRoute>
        }
      /> */}

      {/* Protected route untuk Earthquake */}
      <Route
        path="/dashboard/earthquake"
        element={
          <ProtectedRoute>
            <Earthquake />
          </ProtectedRoute>
        }
      />

      {/* Catch-all route */}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}

export default App;
