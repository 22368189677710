import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import * as React from "react";
import { useEffect, useState } from "react";
import Copyright from "../internals/components/Copyright";
import PageViewsBarChart from "./PageViewsBarChart";
import StatCard from "./StatCard";

export default function MainGrid() {
  const [usersCount, setUsersCount] = useState(0);
  const [devicesCount, setDevicesCount] = useState(0);
  const [apiKeysCount, setApiKeysCount] = useState(0);

  useEffect(() => {
    const firestore = getFirestore();
    const token = localStorage.getItem("idToken");
    const userEmail = localStorage.getItem("userEmail");

    // Mengambil data jumlah pengguna dari backend
    const fetchUsersCount = async () => {
      try {
        const response = await fetch("https://api.adagempa.cloud/total-users", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }); // Ganti URL dengan endpoint backend Anda
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setUsersCount(data.totalUsers);
      } catch (error) {
        console.error("Error fetching users count from backend:", error);
      }
    };
    const fetchApiKeysCount = async () => {
      if (!userEmail) {
        console.error("User email is null or undefined.");
        setApiKeysCount(0);
        return;
      }

      try {
        // Membuat referensi dokumen di Firestore
        const apiKeyDocs = doc(firestore, "apiKey", userEmail);
        const apiKeyDocsSnapshot = await getDoc(apiKeyDocs);

        if (apiKeyDocsSnapshot.exists()) {
          // Periksa apakah ada kunci API
          const apiKeyData = apiKeyDocsSnapshot.data();
          const totalApiKeys = apiKeyData.apiKey ? 1 : 0; // Periksa apakah ada kunci API
          setApiKeysCount(totalApiKeys);
        } else {
          setApiKeysCount(0); // Jika dokumen tidak ada, setel jumlah ke 0
        }
      } catch (error) {
        console.error("Error fetching API keys count:", error);
      }
    };

    // Mengambil data dari "fcmTokens" di Firestore
    const fetchTokens = async () => {
      const tokensDoc = doc(firestore, "fcmTokens", "fcmTokensValue");
      const docSnapshot = await getDoc(tokensDoc);

      if (docSnapshot.exists()) {
        const tokensData = docSnapshot.data();
        const totalDevices = tokensData ? Object.keys(tokensData).length : 0;
        setDevicesCount(totalDevices);
      } else {
        console.error("No such document in Firestore!");
      }
    };

    fetchApiKeysCount();
    fetchUsersCount();
    fetchTokens();
  }, []);

  const data = [
    {
      title: "Total Users",
      value: `${usersCount}`,
      interval: "Now",
      trend: "neutral",
      data: [], // Data tren dapat Anda tambahkan sesuai kebutuhan
    },
    {
      title: "Total Devices",
      value: `${devicesCount}`,
      interval: "Now",
      trend: "neutral",
      data: [], // Data tren dapat Anda tambahkan sesuai kebutuhan
    },
    {
      title: "API Keys Created",
      value: `${apiKeysCount}`, // Jika jumlah API key sama dengan jumlah pengguna
      interval: "Now",
      trend: "neutral",
      data: [], // Data tren dapat Anda tambahkan sesuai kebutuhan
    },
  ];
  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      {/* cards */}
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Overview
      </Typography>
      <Grid
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        {data.map((card, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6, lg: 4 }}>
            <StatCard {...card} />
          </Grid>
        ))}
        {/* <Grid size={{ xs: 12, md: 6 }}>
          <SessionsChart />
        </Grid> */}
        <Grid size={{ xs: 12, md: 6 }}>
          <PageViewsBarChart />
        </Grid>
      </Grid>
      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}
