import * as React from "react";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { BarChart } from "@mui/x-charts/BarChart";
import { useTheme } from "@mui/material/styles";
import { getFirestore, doc, getDoc } from "firebase/firestore";

// Firebase initialization (assumed that Firebase is already set up)
const firestore = getFirestore();

export default function PageViewsBarChart() {
  const theme = useTheme();
  const [devicesCount, setDevicesCount] = useState(0);

  // Mengambil data dari "fcmTokens" di Firestore
  const fetchTokens = async () => {
    const tokensDoc = doc(firestore, "fcmTokens", "fcmTokensValue");
    const docSnapshot = await getDoc(tokensDoc);

    if (docSnapshot.exists()) {
      const tokensData = docSnapshot.data();
      const totalDevices = tokensData ? Object.keys(tokensData).length : 0;
      setDevicesCount(totalDevices);
    } else {
      console.error("No such document in Firestore!");
    }
  };

  fetchTokens();

  const colorPalette = [
    (theme.vars || theme).palette.primary.dark,
    (theme.vars || theme).palette.primary.main,
    (theme.vars || theme).palette.primary.light,
  ];

  return (
    <Card variant="outlined" sx={{ width: "100%" }}>
      <CardContent>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          Total Devices
        </Typography>
        <Stack sx={{ justifyContent: "space-between" }}>
          <Typography variant="h4" component="p">
            {devicesCount}
          </Typography>
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            Total number of devices using the application
          </Typography>
        </Stack>
        <BarChart
          borderRadius={8}
          colors={colorPalette}
          xAxis={[
            {
              scaleType: "band",
              categoryGapRatio: 0.5,
              data: ["Devices"], // Only one category for total devices
            },
          ]}
          series={[
            {
              id: "devices",
              label: "Devices",
              data: [devicesCount], // Use devicesCount as data
              stack: "A",
            },
          ]}
          height={250}
          margin={{ left: 50, right: 0, top: 20, bottom: 20 }}
          grid={{ horizontal: true }}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        />
      </CardContent>
    </Card>
  );
}
