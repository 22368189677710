import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import KeyIcon from "@mui/icons-material/Key";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import PublicIcon from "@mui/icons-material/Public";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { getAuth, signOut } from "firebase/auth";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const mainListItems = [
  { text: "Home", icon: <HomeRoundedIcon />, route: "/dashboard" },
  { text: "Your Api Key", icon: <KeyIcon />, route: "/dashboard/your-api-key" },
  { text: "Clients", icon: <PeopleRoundedIcon />, route: "/dashboard/clients" },
  { text: "Earthquake Data", icon: <PublicIcon />, route: "/dashboard/earthquake" },
];

const secondaryListItems = [
  // { text: "Settings", icon: <SettingsRoundedIcon /> },
  // { text: "About", icon: <InfoRoundedIcon /> },
  // { text: "Feedback", icon: <HelpRoundedIcon /> },
  { text: "Logout", icon: <LogoutRoundedIcon />, action: "logout" },
];

export default function MenuContent() {
  const navigate = useNavigate();

  const handleLogout = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You will be logged out of your account!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, log out!",
    }).then((result) => {
      if (result.isConfirmed) {
        const auth = getAuth();
        signOut(auth)
          .then(() => {
            // Logout successful
            localStorage.removeItem("idToken"); // Remove ID token from localStorage
            localStorage.removeItem("userEmail"); // Remove user email from localStorage
            navigate("/login"); // Redirect to login page
          })
          .catch((error) => {
            // Handle logout error
            console.error("Error logging out:", error);
          });
      }
    });
  };

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: "space-between" }}>
      <List dense>
        {mainListItems.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{ display: "block" }}
            onClick={() => navigate(item.route)} // Navigate on click
          >
            <ListItemButton>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <List dense>
        {secondaryListItems.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{ display: "block" }}
            onClick={item.action === "logout" ? handleLogout : undefined}
          >
            <ListItemButton>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
