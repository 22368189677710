// Impor fungsi yang dibutuhkan dari SDK Firebase
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Konfigurasi Firebase proyek Anda
const firebaseConfig = {
  apiKey: "AIzaSyAEESBjIngqR5Dn1Jnaky7byPsC0zcu4tU",
  authDomain: "ada-gempa.firebaseapp.com",
  databaseURL:
    "https://ada-gempa-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ada-gempa",
  storageBucket: "ada-gempa.firebasestorage.app",
  messagingSenderId: "418796039711",
  appId: "1:418796039711:web:28cfe36964e67aa5b92f2a",
};

// Inisialisasi Firebase
const app = initializeApp(firebaseConfig);

// Inisialisasi Firebase Authentication
const auth = getAuth(app);

export { auth };
