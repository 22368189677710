import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  collection,
  getDocs,
  getFirestore,
  addDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { MaterialReactTable } from "material-react-table";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import Copyright from "../internals/components/Copyright";

export default function EarthquakeGrid() {
  const [earthquakes, setEarthquakes] = useState([]);
  const [newEarthquake, setNewEarthquake] = useState({
    DateTime: "",
    Latitude: "",
    Longitude: "",
    Magnitude: "",
    Kedalaman: "",
    Wilayah: "",
  });

  const firestore = getFirestore();

  useEffect(() => {
    const fetchEarthquakes = async () => {
      try {
        const earthquakeCollection = collection(firestore, "earthquakes");
        const snapshot = await getDocs(earthquakeCollection);

        const earthquakeData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id, // Include the document ID
        }));
        setEarthquakes(earthquakeData);
      } catch (error) {
        console.error("Error fetching earthquake data:", error);
      }
    };

    fetchEarthquakes();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewEarthquake({ ...newEarthquake, [name]: value });
  };

  const handleAddEarthquake = async () => {
    try {
      const { DateTime, Latitude, Longitude, Magnitude, Kedalaman, Wilayah } =
        newEarthquake;
      const Coordinates = `${Latitude},${Longitude}`;

      const earthquakeCollection = collection(firestore, "earthquakes");
      const docRef = await addDoc(earthquakeCollection, {
        DateTime,
        Coordinates,
        Lintang: `${Latitude} LS`,
        Bujur: `${Longitude} BT`,
        Magnitude,
        Kedalaman,
        Wilayah,
        createdAt: new Date(DateTime),
      });

      Swal.fire("Success", "Earthquake data added successfully", "success");

      setEarthquakes([
        ...earthquakes,
        {
          id: docRef.id,
          DateTime,
          Coordinates,
          Lintang: `${Latitude} LS`,
          Bujur: `${Longitude} BT`,
          Magnitude,
          Kedalaman,
          Wilayah,
        },
      ]);
      setNewEarthquake({
        DateTime: "",
        Latitude: "",
        Longitude: "",
        Magnitude: "",
        Kedalaman: "",
        Wilayah: "",
      });
    } catch (error) {
      console.error("Error adding earthquake data:", error);
      Swal.fire("Error", "Failed to add earthquake data", "error");
    }
  };

  const handleDeleteEarthquake = async (id) => {
    try {
      const docRef = doc(firestore, "earthquakes", id);
      await deleteDoc(docRef);

      Swal.fire("Deleted", "Earthquake data deleted successfully", "success");

      // Refresh the earthquake data
      setEarthquakes(earthquakes.filter((earthquake) => earthquake.id !== id));
    } catch (error) {
      console.error("Error deleting earthquake data:", error);
      Swal.fire("Error", "Failed to delete earthquake data", "error");
    }
  };

  const columns = useMemo(
    () => [
      { accessorKey: "DateTime", header: "DateTime" },
      { accessorKey: "Coordinates", header: "Coordinates" },
      { accessorKey: "Lintang", header: "Lintang" },
      { accessorKey: "Bujur", header: "Bujur" },
      { accessorKey: "Magnitude", header: "Magnitude" },
      { accessorKey: "Kedalaman", header: "Kedalaman" },
      { accessorKey: "Wilayah", header: "Wilayah" },
      {
        accessorKey: "actions",
        header: "Actions",
        Cell: ({ row }) => (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDeleteEarthquake(row.original.id)}
          >
            Delete
          </Button>
        ),
      },
    ],
    [earthquakes]
  );

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        DATA GEMPA BUMI
      </Typography>

      <Box component="form" sx={{ mb: 4 }}>
        <TextField
          label="Date & Time"
          name="DateTime"
          type="datetime-local"
          value={newEarthquake.DateTime}
          onChange={handleInputChange}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Latitude"
          name="Latitude"
          type="number"
          value={newEarthquake.Latitude}
          onChange={handleInputChange}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Longitude"
          name="Longitude"
          type="number"
          value={newEarthquake.Longitude}
          onChange={handleInputChange}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Magnitude"
          name="Magnitude"
          type="number"
          value={newEarthquake.Magnitude}
          onChange={handleInputChange}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Kedalaman"
          name="Kedalaman"
          value={newEarthquake.Kedalaman}
          onChange={handleInputChange}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Wilayah"
          name="Wilayah"
          value={newEarthquake.Wilayah}
          onChange={handleInputChange}
          fullWidth
          sx={{ mb: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddEarthquake}
        >
          Add Earthquake
        </Button>
      </Box>

      <MaterialReactTable columns={columns} data={earthquakes} />

      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}
